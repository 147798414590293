<template>
  <div style="background-color: #ffffff; height: 100%">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave('PREPARE')"
      @head-submit="headSave('FINISHED')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <!-- <avue-form :option="formOptions" ref="addForm" v-model="addForm">
          <template slot="eventCode">
            <el-input
              placeholder="请输入记录编号"
              v-model="addForm.eventCode"
              :disabled="isAutomatic || pageDisabled || addForm.id || formId"
            >
              <span slot="append">
                自动生成
                <el-switch
                  :disabled="pageDisabled || addForm.id || formId"
                  v-model="isAutomatic"
                  active-color="#13ce66"
                  @change="handleSwitch"
                >
                </el-switch>
              </span>
            </el-input>
          </template>
        
        </avue-form> -->

        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="120px"
        >
          <div class="formTopic">基本信息</div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="记录编号" prop="emissionCode">
                <el-input
                  placeholder="请输入记录编号"
                  v-model="addForm.emissionCode"
                  :disabled="
                    isAutomatic || pageDisabled || addForm.id || formId
                  "
                >
                  <span slot="append">
                    自动生成
                    <el-switch
                      :disabled="pageDisabled || addForm.id || formId"
                      v-model="isAutomatic"
                      active-color="#13ce66"
                      @change="handleSwitch"
                    >
                    </el-switch>
                  </span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="污染物类型" prop="pollutionType">
                <el-select
                  v-model="addForm.pollutionType"
                  placeholder="请选择污染物类型"
                  :disabled="pageDisabled"
                >
                  <el-option
                    v-for="item in epPollutionType"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="污染物明细" prop="pollutionDetail">
                <el-input
                  placeholder="请输入污染物明细"
                  v-model="addForm.pollutionDetail"
                  maxlength="125"
                  show-word-limit
                  :disabled="pageDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="排放量" prop="quantity">
                <el-input
                  placeholder="请输入排放量"
                  v-model="addForm.quantity"
                  maxlength="64"
                  show-word-limit
                  :disabled="pageDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检测点位" prop="pointName">
                <el-input
                  placeholder="请选择检测点位"
                  suffixIcon="el-icon-search"
                  v-model="addForm.pointName"
                  :disabled="pageDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排污起止时间" prop="datetimerange">
                <el-date-picker
                  style="width: 100%"
                  v-model="addForm.datetimerange"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :disabled="pageDisabled"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <template>
            <div class="formTopic">排放检测情况</div>

            <el-row>
              <el-col :span="24"> </el-col>
            </el-row>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";

import * as API from "@/api/environmentProtect/pollutionEmissions";
import { getPageCode } from "@/api/system/serialNumber";
import { dictionaryBiz } from "@/api/reportTasks";
import { dateFormat } from "@/util/date";
import { mapGetters } from "vuex";
export default {
  components: {
    HeadLayout,
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      addForm: {
        emissionCode: "",
        pollutionType: "",
        pollutionDetail: "",
        quantity: "",
        pointName: "",
        pointId: "",
        orgId: "",
        orgCode: "",
        status: 0,
        datetimerange: [],
      },
      rules: {
        emissionCode: [
          { required: true, message: "请输入记录编号", trigger: "blur" },
          { max: 64, message: "记录编号不能超过64个字符", trigger: "blur" },
        ],

        pollutionDetail: [
          { required: true, message: "请输入排放明细", trigger: "blur" },
        ],
        quantity: [
          { required: true, message: "请输入排放量", trigger: "blur" },
        ],

        datetimerange: [
          { required: true, message: "请选择发生时间", trigger: "change" },
        ],
      },

      isAutomatic: true,

      epPollutionType: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),

    pageDisabled() {
      // 如果查看 或者 提交状态（未执行）则禁用 已执行禁用
      if (this.formType == "view") {
        return true;
      } else {
        return false;
      }
    },

    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType) && !this.pageDisabled) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });

        result.push({
          label: "提交",
          emit: "head-submit",
          type: "button",
          btnOptType: "refer",
        });
      }

      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化新增/编辑/查看页面
    async init() {
      let type = this.$route.query.type;
      this.formType = type;
      this.headTitle = "排放记录";
      if (this.formType == "add") {
        await this.handleSwitch();
      } else if (this.formType == "edit") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      } else if (this.formType == "view") {
        this.formId = this.$route.query.id;
        this.getDetails(this.formId);
      }
      dictionaryBiz("ep_pollution_type").then((res) => {
        this.epPollutionType = res.data.data;
      });
    },
    // 基本信息预案编号是否自动生成
    async handleSwitch() {
      if (this.isAutomatic) {
        await this.getPageCode();
      } else {
        this.addForm.eventCode = "";
      }
    },
    async getPageCode() {
      // 获取编码
      await getPageCode({ ruleCode: "emission_Code" }).then((res) => {
        this.$set(this.addForm, "emissionCode", res.data.data || "");
        this.$forceUpdate();
      });
    },

    headSave(status) {
      this.saveOrSubmitFor(status);
    },
    // 保存提交改变状态
    saveOrSubmitFor(type) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveLoading = true;

          this.addForm.status = type == "PREPARE" ? 0 : 1;

          if (
            type == "FINISHED" ||
            this.formType == "edit" ||
            (this.formType == "add" && this.formId)
          ) {
            this.addForm.id = this.formId;
          }
          this.addForm.orgId = this.userInfo.dept_id;
          this.addForm.orgCode = this.userInfo.dept_id;
          const params = JSON.parse(JSON.stringify(this.addForm));

          params.startTime = params.datetimerange[0];
          params.endTime = params.datetimerange[1];

          API.pollutionRecordSubmit(params)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code == 200) {
                this.formId = data.id;
                this.getDetails(this.formId);
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                if (data.status) this.formType = "view";
              } else {
                this.$message.error(msg);
              }
              this.saveLoading = false;
            })
            .catch((err) => {});
        }
      });
    },

    headCancel() {
      this.$refs.addForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 获取详情
    getDetails(id) {
      API.pollutionRecordDetail({ id }).then((res) => {
        if (res.data.code == 200) {
          const { data } = res.data;


          Object.keys(this.addForm).forEach((key) => {
            if (key == "datetimerange") {
              this.addForm[key] = [data.startTime, data.endTime];
            } else {
              this.addForm[key] = data[key];
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}
.formTopic {
  // margin: 0 12px;
  margin-bottom: 12px;

  // width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
</style>
