var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#ffffff", height: "100%" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("PREPARE")
          },
          "head-submit": function ($event) {
            return _vm.headSave("FINISHED")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "addForm",
                attrs: {
                  model: _vm.addForm,
                  rules: _vm.rules,
                  "label-width": "120px",
                },
              },
              [
                _c("div", { staticClass: "formTopic" }, [_vm._v("基本信息")]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "记录编号", prop: "emissionCode" },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "请输入记录编号",
                                  disabled:
                                    _vm.isAutomatic ||
                                    _vm.pageDisabled ||
                                    _vm.addForm.id ||
                                    _vm.formId,
                                },
                                model: {
                                  value: _vm.addForm.emissionCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "emissionCode", $$v)
                                  },
                                  expression: "addForm.emissionCode",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "append" }, slot: "append" },
                                  [
                                    _vm._v(
                                      "\n                  自动生成\n                  "
                                    ),
                                    _c("el-switch", {
                                      attrs: {
                                        disabled:
                                          _vm.pageDisabled ||
                                          _vm.addForm.id ||
                                          _vm.formId,
                                        "active-color": "#13ce66",
                                      },
                                      on: { change: _vm.handleSwitch },
                                      model: {
                                        value: _vm.isAutomatic,
                                        callback: function ($$v) {
                                          _vm.isAutomatic = $$v
                                        },
                                        expression: "isAutomatic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "污染物类型",
                              prop: "pollutionType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择污染物类型",
                                  disabled: _vm.pageDisabled,
                                },
                                model: {
                                  value: _vm.addForm.pollutionType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "pollutionType", $$v)
                                  },
                                  expression: "addForm.pollutionType",
                                },
                              },
                              _vm._l(_vm.epPollutionType, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "污染物明细",
                              prop: "pollutionDetail",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入污染物明细",
                                maxlength: "125",
                                "show-word-limit": "",
                                disabled: _vm.pageDisabled,
                              },
                              model: {
                                value: _vm.addForm.pollutionDetail,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "pollutionDetail", $$v)
                                },
                                expression: "addForm.pollutionDetail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排放量", prop: "quantity" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入排放量",
                                maxlength: "64",
                                "show-word-limit": "",
                                disabled: _vm.pageDisabled,
                              },
                              model: {
                                value: _vm.addForm.quantity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "quantity", $$v)
                                },
                                expression: "addForm.quantity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检测点位", prop: "pointName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请选择检测点位",
                                suffixIcon: "el-icon-search",
                                disabled: _vm.pageDisabled,
                              },
                              model: {
                                value: _vm.addForm.pointName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "pointName", $$v)
                                },
                                expression: "addForm.pointName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "排污起止时间",
                              prop: "datetimerange",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetimerange",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                disabled: _vm.pageDisabled,
                              },
                              model: {
                                value: _vm.addForm.datetimerange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "datetimerange", $$v)
                                },
                                expression: "addForm.datetimerange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                [
                  _c("div", { staticClass: "formTopic" }, [
                    _vm._v("排放检测情况"),
                  ]),
                  _c("el-row", [_c("el-col", { attrs: { span: 24 } })], 1),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }